import { useState } from "react"
import { PageHeader } from "~/ui/PageHeader"
import { organizationTestResponsePath } from "~/common/paths"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { CandidateTests } from "~/tests/CandidateTests"
import {
  CandidateTestSortEnum,
  CandidateTestsOrganizationFilter,
} from "~/__generated__/graphql"
import {
  CandidateTestsTableBottomFilter,
  CandidateTestsTableTopFilter,
} from "~/tests/CandidateTestsTableFilter"
import { ExportCsvLink } from "~/tests/ExportCsvLink"

export const OrganizationCandidatesScreen = () => {
  const { organizationId } = useParams()

  const [filter, setFilter] = useState<CandidateTestsOrganizationFilter>({})

  invariant(organizationId)

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title="Candidates"
        subhead={
          <div>
            <div>Candidate who have responded to my tests</div>
            <ExportCsvLink />
          </div>
        }
      />
      <CandidateTestsTableTopFilter
        organizationId={organizationId}
        filter={filter}
        onFilterChange={setFilter}
      />
      <CandidateTests
        columns={[
          "candidate",
          "test",
          "timeTakenSeconds",
          "manualScore",
          "aiScore",
          "actions",
        ]}
        defaultSort={CandidateTestSortEnum.CompletedAt}
        organizationId={organizationId}
        filter={filter}
        viewPath={(candidateTest) =>
          organizationTestResponsePath({
            organizationId,
            testId: candidateTest.test.id,
            candidateTestId: candidateTest.id,
          })
        }
      />
      <CandidateTestsTableBottomFilter
        filter={filter}
        onFilterChange={setFilter}
      />
    </div>
  )
}

import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import {
  CandidateTestsTable,
  CandidateTestsTableProps,
} from "~/tests/CandidateTestsTable"
import { useSearchParams } from "react-router-dom"
import { usePagination } from "~/common/usePagination"
import { useEffect, useState } from "react"
import {
  CandidateTestSortEnum,
  SortDirectionEnum,
  CandidateTestsOrganizationFilter,
} from "~/__generated__/graphql"
import { Pagination } from "~/ui/Pagination"

interface CandidateTestsProps
  extends Pick<CandidateTestsTableProps, "columns" | "viewPath"> {
  userId?: string
  testId?: string
  organizationId?: string
  defaultSort?: CandidateTestSortEnum
  defaultDirection?: SortDirectionEnum
  searchParamsPrefix?: string
  perPage?: number
  filter?: CandidateTestsOrganizationFilter
}

export class CandidateTestSort {
  sort: CandidateTestSortEnum
  direction: SortDirectionEnum

  constructor(sort: CandidateTestSortEnum, direction: SortDirectionEnum) {
    this.sort = sort
    this.direction = direction
  }

  reverseDirection() {
    return this.direction === SortDirectionEnum.Ascending
      ? SortDirectionEnum.Descending
      : SortDirectionEnum.Ascending
  }
}

export const CandidateTests = ({
  userId,
  testId,
  organizationId,
  defaultSort = CandidateTestSortEnum.TimeTakenSeconds,
  defaultDirection = SortDirectionEnum.Descending,
  searchParamsPrefix = "responses_",
  perPage = 20,
  filter,
  columns,
  viewPath,
}: CandidateTestsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [candidateTestsSort, setCandidateTestsSort] = useState(
    new CandidateTestSort(
      (searchParams.get(
        `${searchParamsPrefix}sort`
      ) as CandidateTestSortEnum) || defaultSort,
      (searchParams.get(
        `${searchParamsPrefix}direction`
      ) as SortDirectionEnum) || defaultDirection
    )
  )

  useEffect(() => {
    if (candidateTestsSort.sort === defaultSort) {
      searchParams.delete(`${searchParamsPrefix}sort`)
    } else {
      searchParams.set(`${searchParamsPrefix}sort`, candidateTestsSort.sort)
    }

    if (candidateTestsSort.direction === defaultDirection) {
      searchParams.delete(`${searchParamsPrefix}direction`)
    } else {
      searchParams.set(
        `${searchParamsPrefix}direction`,
        candidateTestsSort.direction
      )
    }

    setSearchParams(searchParams)
  }, [
    candidateTestsSort,
    setSearchParams,
    searchParams,
    searchParamsPrefix,
    defaultSort,
    defaultDirection,
  ])

  const { after, paginate, page } = usePagination({
    perPage: perPage,
    paramKey: `${searchParamsPrefix}page`,
  })
  const { loading, data, error, refetch } = useQuery(
    CANDIDATE_TESTS_QUERY_DOCUMENT,
    {
      variables: {
        userId,
        testId,
        organizationId,
        candidateTestsFirst: perPage,
        candidateTestsAfter: after,
        candidateTestsSort: candidateTestsSort.sort,
        direction: candidateTestsSort.direction,
        candidateTestsOrganizationFilter: filter,
      },
    }
  )

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading responses." />

  const onCandidateTestArchivedChanged = () => {
    if (filter?.archived !== true) {
      refetch()
    }
  }

  return (
    <>
      {data.candidateTests.nodes.length > 0 ? (
        <>
          <CandidateTestsTable
            data={data.candidateTests.nodes}
            columns={columns}
            viewPath={viewPath}
            sort={candidateTestsSort}
            setSort={setCandidateTestsSort}
            onCandidateTestArchivedChanged={onCandidateTestArchivedChanged}
          />
          {data.candidateTests.pageCount > 1 && (
            <Pagination
              page={page}
              pageCount={data.candidateTests.pageCount}
              paginate={paginate}
            />
          )}
        </>
      ) : (
        <EmptyData>No test taken yet.</EmptyData>
      )}
    </>
  )
}

gql(`
  fragment CandidateTest_Table on CandidateTest {
    id
    startedAt
    completedAt
    timeTakenSeconds
    manualScore
    aiScore
    phoneVerificationCompleted
    state
    archivedAt

    test {
      id
      name
      requirePhoneVerification
      requireEmailVerification
    }
    user {
      id
      name
      email
      phone
    }
  }
`)

const CANDIDATE_TESTS_QUERY_DOCUMENT = gql(`
  query CandidateTestsQuery($userId: ID, $testId: ID, $organizationId: ID, $candidateTestsFirst: Int!, $candidateTestsAfter: String, $candidateTestsSort: CandidateTestSortEnum, $direction: SortDirectionEnum, $candidateTestsOrganizationFilter: CandidateTestsOrganizationFilter) {
    candidateTests(userId: $userId, testId: $testId, organizationId: $organizationId, first: $candidateTestsFirst, after: $candidateTestsAfter, sort: $candidateTestsSort, direction: $direction, filter: $candidateTestsOrganizationFilter) {
      pageCount(first: $candidateTestsFirst)
      nodes {
        ...CandidateTest_Table
      }
    }
  }
`)
